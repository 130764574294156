@layer mantine {.m-b37d9ac7 {
  width: calc(100% - var(--mantine-spacing-md) * 2);
  position: fixed;
  z-index: var(--notifications-z-index);
  top: var(--notifications-top);
  left: var(--notifications-left);
  right: var(--notifications-right);
  bottom: var(--notifications-bottom);
  transform: var(--notifications-transform);
  max-width: var(--notifications-container-width);
}

.m-5ed0edd0 + .m-5ed0edd0 {
    margin-top: var(--mantine-spacing-md);
  }
}
@layer mantine {.m-8f2832ae {
  position: fixed;
  top: 0;
  inset-inline: 0;
  z-index: var(--nprogress-z-index);
  background-color: transparent;
  transition: opacity 150ms ease;
  transition-delay: 50ms;
  opacity: 0;
  overflow: visible;
}

  .m-8f2832ae:where([data-mounted]) {
    opacity: 1;
  }

.m-7a0fe999 {
  position: relative;
  transition: width 150ms ease;
  overflow: visible;
}

.m-7a0fe999::before {
    content: '';
    position: absolute;
    width: calc(6.25rem * var(--mantine-scale));
    height: var(--progress-size);
    top: 0;
    inset-inline-end: 1px;
    transform: rotate(4deg) translateY(-4px);
    box-shadow:
      0 0 10px var(--progress-section-color),
      0 0 5px var(--progress-section-color);
  }
}
@layer mantine {.m-5caae6d3 {
  display: inline-block;
  padding: calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale));
  font-size: calc(0.8125rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-xs);
  line-height: var(--code-line-height, var(--mantine-line-height));
  font-family: var(--mantine-font-family-monospace);
}

.m-2c47c4fd {
  --code-line-height: 1.7;
  display: block;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  margin: 0;
}

.m-e58679f3 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.m-be7e9c9c {
  display: flex;
  margin-top: calc(0.4375rem * var(--mantine-scale));
  margin-inline-end: calc(0.4375rem * var(--mantine-scale));
}

.m-5caae85b,
.m-d498bab7 {
  background-color: transparent;
  opacity: 0.8;
  margin: 0;
  color: var(--mantine-color-dimmed);
}

@media (hover: hover) {

  .m-5caae85b:hover, .m-d498bab7:hover {
    opacity: 1;
    background-color: transparent;
    color: var(--mantine-color-dimmed);
  }
}

@media (hover: none) {

  .m-5caae85b:active, .m-d498bab7:active {
    opacity: 1;
    background-color: transparent;
    color: var(--mantine-color-dimmed);
  }
}

@media (max-width: 40em) {

.m-5caae85b,
.m-d498bab7 {
    display: none
}
  }

.m-5caae85b {
  position: absolute;
  top: calc(0.3125rem * var(--mantine-scale));
  inset-inline-end: calc(0.3125rem * var(--mantine-scale));
  z-index: 1;
}

.m-5cac2e62 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--mantine-font-size-xs);
  gap: calc(0.4375rem * var(--mantine-scale));
  padding: calc(0.4375rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale));
  font-family: var(--mantine-font-family-monospace);
  font-weight: 700;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  opacity: 0.8;
  border: calc(0.0625rem * var(--mantine-scale)) solid;
  border-top: 0;
  border-inline-start: 0;
  white-space: nowrap;
  margin: 0;
}

@media (hover: hover) {

  .m-5cac2e62:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m-5cac2e62:active {
    opacity: 1;
  }
}

.m-5cac2e62:where(:last-of-type) {
    border-end-end-radius: var(--mantine-radius-sm);
  }

.m-5cac2e62:where(:only-child) {
    cursor: default;
  }

.m-5cac2e62:where([data-active]) {
    opacity: 1;
  }

:where([data-mantine-color-scheme='light']) .m-5cac2e62:where([data-active]) {
      background-color: var(--mantine-color-white);
      color: var(--mantine-color-black);
}

:where([data-mantine-color-scheme='dark']) .m-5cac2e62:where([data-active]) {
      background-color: var(--mantine-color-dark-6);
      color: var(--mantine-color-white);
}

:where([data-mantine-color-scheme='light']) .m-5cac2e62 {
    color: var(--mantine-color-gray-8);
    border-color: var(--mantine-color-gray-2);
}

:where([data-mantine-color-scheme='dark']) .m-5cac2e62 {
    color: var(--mantine-color-dark-0);
    border-color: var(--mantine-color-dark-4);
}

.m-38d99e51 {
  display: flex;
}

.m-9f507240 {
  max-height: var(--ch-max-collapsed-height);
  overflow: hidden;
  position: relative;
}

.m-9f507240::before {
    content: '';
    z-index: 100;
    position: absolute;
    inset: 0;
    pointer-events: none;
    background-image: linear-gradient(0deg, var(--code-background) 16%, rgba(0, 0, 0, 0) 100%);
    border-radius: calc(var(--mantine-radius-md) - calc(0.0625rem * var(--mantine-scale)));
  }

.m-9f507240:where([data-expanded]) {
    max-height: none;
  }

.m-9f507240:where([data-expanded])::before {
      display: none;
    }

.m-c9378bc2 {
  position: absolute;
  bottom: 0;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-anchor);
  width: 100%;
  text-align: center;
  z-index: 101;
  padding-top: var(--mantine-spacing-xs);
  padding-bottom: var(--mantine-spacing-xs);
}

.m-c9378bc2[data-hidden] {
    display: none;
  }

.m-5cb1b9c8 {
  margin-top: 0;
  position: relative;
}

.m-5cb1b9c8:where([data-collapsed]) .mantine-ScrollArea-viewport > div {
    display: block !important;
  }

.m-b46cddfb {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
}

.m-b46cddfb > svg {
    display: block;
  }

.m-1f5e827e {
  color: var(--code-text-color);
  background: var(--code-background);
}

  :where([data-mantine-color-scheme='light']) .m-1f5e827e {
    --code-text-color: var(--mantine-color-gray-7);
    --code-background: var(--mantine-color-gray-0);
    --code-comment-color: var(--mantine-color-gray-6);
    --code-keyword-color: var(--mantine-color-violet-8);
    --code-tag-color: var(--mantine-color-red-9);
    --code-literal-color: var(--mantine-color-blue-6);
    --code-string-color: var(--mantine-color-blue-9);
    --code-variable-color: var(--mantine-color-lime-9);
    --code-class-color: var(--mantine-color-orange-9);
}

  :where([data-mantine-color-scheme='dark']) .m-1f5e827e {
    --code-text-color: var(--mantine-color-dark-1);
    --code-background: var(--mantine-color-dark-8);
    --code-comment-color: var(--mantine-color-dark-3);
    --code-keyword-color: var(--mantine-color-violet-3);
    --code-tag-color: var(--mantine-color-yellow-4);
    --code-literal-color: var(--mantine-color-blue-4);
    --code-string-color: var(--mantine-color-green-6);
    --code-variable-color: var(--mantine-color-blue-2);
    --code-class-color: var(--mantine-color-orange-5);
}

  .m-1f5e827e .hljs-comment,
  .m-1f5e827e .hljs-quote {
    font-style: italic;
    color: var(--code-comment-color);
  }

  .m-1f5e827e .hljs-doctag,
  .m-1f5e827e .hljs-formula,
  .m-1f5e827e .hljs-keyword {
    color: var(--code-keyword-color);
  }

  .m-1f5e827e .hljs-deletion,
  .m-1f5e827e .hljs-name,
  .m-1f5e827e .hljs-section,
  .m-1f5e827e .hljs-selector-tag,
  .m-1f5e827e .hljs-subst {
    color: var(--code-tag-color);
  }

  .m-1f5e827e .hljs-literal {
    color: var(--code-literal-color);
  }

  .m-1f5e827e .hljs-addition,
  .m-1f5e827e .hljs-attribute,
  .m-1f5e827e .hljs-meta .hljs-string,
  .m-1f5e827e .hljs-regexp,
  .m-1f5e827e .hljs-string {
    color: var(--code-string-color);
  }

  .m-1f5e827e .hljs-attr,
  .m-1f5e827e .hljs-number,
  .m-1f5e827e .hljs-selector-attr,
  .m-1f5e827e .hljs-selector-class,
  .m-1f5e827e .hljs-selector-pseudo,
  .m-1f5e827e .hljs-template-variable,
  .m-1f5e827e .hljs-type,
  .m-1f5e827e .hljs-variable {
    color: var(--code-variable-color);
  }

  .m-1f5e827e .hljs-bullet,
  .m-1f5e827e .hljs-link,
  .m-1f5e827e .hljs-meta,
  .m-1f5e827e .hljs-selector-id,
  .m-1f5e827e .hljs-symbol,
  .m-1f5e827e .hljs-title,
  .m-1f5e827e .hljs-built_in,
  .m-1f5e827e .hljs-class .hljs-title,
  .m-1f5e827e .hljs-title.class_ {
    color: var(--code-class-color);
  }

  .m-1f5e827e .hljs-emphasis {
    font-style: italic;
  }

  .m-1f5e827e .hljs-strong {
    font-weight: 700;
  }

  .m-1f5e827e .hljs-link {
    text-decoration: underline;
  }
}
@layer base, reset, mantine, tokens, recipes, utilities;

@font-face {
	font-family: 'Unbounded';
	src: url('/Unbounded-VariableFont_wght.ttf') format('truetype');
	font-weight: 100 900;
}

* {
	-webkit-hyphens: auto;
	        hyphens: auto;
}

html[data-mantine-color-scheme='dark'] *::-webkit-scrollbar {
		width: 8px; /* Mostly for vertical scrollbars */
		height: 8px; /* Mostly for horizontal scrollbars */
	}

html[data-mantine-color-scheme='dark'] *::-webkit-scrollbar-thumb {
		background: rgba(255 255 255 / 0.2);
		border-radius: 2px;
	}

html[data-mantine-color-scheme='dark'] ::-webkit-scrollbar-corner {
		background: transparent;
	}

html[data-mantine-color-scheme='dark'] *::-webkit-scrollbar-track {
		background: rgba(255 255 255 / 0.1);
		border-radius: 2px;
		margin: 2px;
	}

html[data-mantine-color-scheme='light'] *::-webkit-scrollbar {
		width: 8px; /* Mostly for vertical scrollbars */
		height: 8px; /* Mostly for horizontal scrollbars */
	}

html[data-mantine-color-scheme='light'] *::-webkit-scrollbar-thumb {
		background: rgba(0 0 0 / 0.2);
		border-radius: 2px;
	}

html[data-mantine-color-scheme='light'] ::-webkit-scrollbar-corner {
		background: transparent;
	}

html[data-mantine-color-scheme='light'] *::-webkit-scrollbar-track {
		background: rgba(0 0 0 / 0.1);
		border-radius: 2px;
	}

:root {
	--default-border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);

	--overlay-filter: blur(10px);

	--mantine-color-body: oklch(95% 0 245.4);

	--paper-background-color: color-mix(in oklch, var(--mantine-color-body) 50%, white);
	--paper-background-color-2: color-mix(in oklch, var(--mantine-color-body) 40%, white);

	--paper-border: 1px solid rgba(0, 0, 0, 0.06);

	--debug-visibility: hidden;

	--input-bg: var(--mantine-color-gray-1);
}

:root .jotai-devtools-trigger-button {
		left: unset;
		right: 1rem;
		bottom: 1rem;
		visibility: var(--debug-visibility);
	}

:root[data-mantine-color-scheme='dark'] {
	--default-border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);

	--mantine-color-body: oklch(15% 0 245.4);

	--paper-background-color: color-mix(in oklch, var(--mantine-color-body) 92%, white);
	--paper-background-color-2: color-mix(in oklch, var(--mantine-color-body) 90%, white);

	--paper-border: 1px solid rgba(255, 255, 255, 0.13);
	--mantine-color-text: rgb(240, 240, 240);
	--mantine-color-dimmed: color-mix(in oklch, var(--mantine-color-text) 82%, black);

	--input-bg: var(--mantine-color-dark-5);
}

@layer {
	a {
		text-decoration: none;
	}

	.mantine-Switch-root .mantine-Switch-body:has(input:not([disabled])) * {
		cursor: pointer;
	}
		.mantine-Modal-root .mantine-Modal-content {
			border: 1px solid rgba(0, 0, 0, 0.06);
		}
			[data-mantine-color-scheme='dark'] .mantine-Modal-root .mantine-Modal-content{
			border: 1px solid rgba(255, 255, 255, 0.03);
	}
		.mantine-Paper-root[data-with-border] {
			/* border: 1px solid light-dark(rgba(0, 0, 0, 0.06), rgba(255, 255, 255, 0.1)); */
			border: var(--paper-border);
		}
		.mantine-Paper-root{

		background-color: var(--paper-background-color);
	}
}

* {
	text-rendering: optimizeLegibility;
}

fieldset {
	border: none;
	padding: 0;
}

.tabler-icon {
	/* fill: currentColor; */
	stroke-width: 2px;
}

:root {
}

@layer base {
  :root {
    --made-with-panda: '🐼';
}

  *,*::before,*::after,::backdrop {
    --blur: /*-*/ /*-*/;
    --brightness: /*-*/ /*-*/;
    --contrast: /*-*/ /*-*/;
    --grayscale: /*-*/ /*-*/;
    --hue-rotate: /*-*/ /*-*/;
    --invert: /*-*/ /*-*/;
    --saturate: /*-*/ /*-*/;
    --sepia: /*-*/ /*-*/;
    --drop-shadow: /*-*/ /*-*/;
    --backdrop-blur: /*-*/ /*-*/;
    --backdrop-brightness: /*-*/ /*-*/;
    --backdrop-contrast: /*-*/ /*-*/;
    --backdrop-grayscale: /*-*/ /*-*/;
    --backdrop-hue-rotate: /*-*/ /*-*/;
    --backdrop-invert: /*-*/ /*-*/;
    --backdrop-opacity: /*-*/ /*-*/;
    --backdrop-saturate: /*-*/ /*-*/;
    --backdrop-sepia: /*-*/ /*-*/;
    --scroll-snap-strictness: proximity;
    --border-spacing-x: 0;
    --border-spacing-y: 0;
    --translate-x: 0;
    --translate-y: 0;
    --rotate: 0;
    --skew-x: 0;
    --skew-y: 0;
    --scale-x: 1;
    --scale-y: 1;
}
}

@layer tokens {
  :where(:root, :host) {
    --aspect-ratios-square: 1 / 1;
    --aspect-ratios-landscape: 4 / 3;
    --aspect-ratios-portrait: 3 / 4;
    --aspect-ratios-wide: 16 / 9;
    --aspect-ratios-ultrawide: 18 / 5;
    --aspect-ratios-golden: 1.618 / 1;
    --borders-none: none;
    --easings-default: cubic-bezier(0.4, 0, 0.2, 1);
    --easings-linear: linear;
    --easings-in: cubic-bezier(0.4, 0, 1, 1);
    --easings-out: cubic-bezier(0, 0, 0.2, 1);
    --easings-in-out: cubic-bezier(0.4, 0, 0.2, 1);
    --durations-fastest: 50ms;
    --durations-faster: 100ms;
    --durations-fast: 150ms;
    --durations-normal: 200ms;
    --durations-slow: 300ms;
    --durations-slower: 400ms;
    --durations-slowest: 500ms;
    --radii-xs: 0.125rem;
    --radii-sm: 0.25rem;
    --radii-md: 0.375rem;
    --radii-lg: 0.5rem;
    --radii-xl: 0.75rem;
    --radii-2xl: 1rem;
    --radii-3xl: 1.5rem;
    --radii-full: 9999px;
    --font-weights-thin: 100;
    --font-weights-extralight: 200;
    --font-weights-light: 300;
    --font-weights-normal: 400;
    --font-weights-medium: 500;
    --font-weights-semibold: 600;
    --font-weights-bold: 700;
    --font-weights-extrabold: 800;
    --font-weights-black: 900;
    --line-heights-none: 1;
    --line-heights-tight: 1.25;
    --line-heights-snug: 1.375;
    --line-heights-normal: 1.5;
    --line-heights-relaxed: 1.625;
    --line-heights-loose: 2;
    --fonts-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --fonts-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    --fonts-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --letter-spacings-tighter: -0.05em;
    --letter-spacings-tight: -0.025em;
    --letter-spacings-normal: 0em;
    --letter-spacings-wide: 0.025em;
    --letter-spacings-wider: 0.05em;
    --letter-spacings-widest: 0.1em;
    --font-sizes-2xs: 0.5rem;
    --font-sizes-xs: 0.75rem;
    --font-sizes-sm: 0.875rem;
    --font-sizes-md: 1rem;
    --font-sizes-lg: 1.125rem;
    --font-sizes-xl: 1.25rem;
    --font-sizes-2xl: 1.5rem;
    --font-sizes-3xl: 1.875rem;
    --font-sizes-4xl: 2.25rem;
    --font-sizes-5xl: 3rem;
    --font-sizes-6xl: 3.75rem;
    --font-sizes-7xl: 4.5rem;
    --font-sizes-8xl: 6rem;
    --font-sizes-9xl: 8rem;
    --shadows-xs: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadows-sm: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --shadows-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadows-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --shadows-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --shadows-2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --shadows-inner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --colors-current: currentColor;
    --colors-black: #000;
    --colors-white: #fff;
    --colors-transparent: rgb(0 0 0 / 0);
    --colors-rose-50: #fff1f2;
    --colors-rose-100: #ffe4e6;
    --colors-rose-200: #fecdd3;
    --colors-rose-300: #fda4af;
    --colors-rose-400: #fb7185;
    --colors-rose-500: #f43f5e;
    --colors-rose-600: #e11d48;
    --colors-rose-700: #be123c;
    --colors-rose-800: #9f1239;
    --colors-rose-900: #881337;
    --colors-rose-950: #4c0519;
    --colors-pink-50: #fdf2f8;
    --colors-pink-100: #fce7f3;
    --colors-pink-200: #fbcfe8;
    --colors-pink-300: #f9a8d4;
    --colors-pink-400: #f472b6;
    --colors-pink-500: #ec4899;
    --colors-pink-600: #db2777;
    --colors-pink-700: #be185d;
    --colors-pink-800: #9d174d;
    --colors-pink-900: #831843;
    --colors-pink-950: #500724;
    --colors-fuchsia-50: #fdf4ff;
    --colors-fuchsia-100: #fae8ff;
    --colors-fuchsia-200: #f5d0fe;
    --colors-fuchsia-300: #f0abfc;
    --colors-fuchsia-400: #e879f9;
    --colors-fuchsia-500: #d946ef;
    --colors-fuchsia-600: #c026d3;
    --colors-fuchsia-700: #a21caf;
    --colors-fuchsia-800: #86198f;
    --colors-fuchsia-900: #701a75;
    --colors-fuchsia-950: #4a044e;
    --colors-purple-50: #faf5ff;
    --colors-purple-100: #f3e8ff;
    --colors-purple-200: #e9d5ff;
    --colors-purple-300: #d8b4fe;
    --colors-purple-400: #c084fc;
    --colors-purple-500: #a855f7;
    --colors-purple-600: #9333ea;
    --colors-purple-700: #7e22ce;
    --colors-purple-800: #6b21a8;
    --colors-purple-900: #581c87;
    --colors-purple-950: #3b0764;
    --colors-violet-50: #f5f3ff;
    --colors-violet-100: #ede9fe;
    --colors-violet-200: #ddd6fe;
    --colors-violet-300: #c4b5fd;
    --colors-violet-400: #a78bfa;
    --colors-violet-500: #8b5cf6;
    --colors-violet-600: #7c3aed;
    --colors-violet-700: #6d28d9;
    --colors-violet-800: #5b21b6;
    --colors-violet-900: #4c1d95;
    --colors-violet-950: #2e1065;
    --colors-indigo-50: #eef2ff;
    --colors-indigo-100: #e0e7ff;
    --colors-indigo-200: #c7d2fe;
    --colors-indigo-300: #a5b4fc;
    --colors-indigo-400: #818cf8;
    --colors-indigo-500: #6366f1;
    --colors-indigo-600: #4f46e5;
    --colors-indigo-700: #4338ca;
    --colors-indigo-800: #3730a3;
    --colors-indigo-900: #312e81;
    --colors-indigo-950: #1e1b4b;
    --colors-blue-50: #eff6ff;
    --colors-blue-100: #dbeafe;
    --colors-blue-200: #bfdbfe;
    --colors-blue-300: #93c5fd;
    --colors-blue-400: #60a5fa;
    --colors-blue-500: #3b82f6;
    --colors-blue-600: #2563eb;
    --colors-blue-700: #1d4ed8;
    --colors-blue-800: #1e40af;
    --colors-blue-900: #1e3a8a;
    --colors-blue-950: #172554;
    --colors-sky-50: #f0f9ff;
    --colors-sky-100: #e0f2fe;
    --colors-sky-200: #bae6fd;
    --colors-sky-300: #7dd3fc;
    --colors-sky-400: #38bdf8;
    --colors-sky-500: #0ea5e9;
    --colors-sky-600: #0284c7;
    --colors-sky-700: #0369a1;
    --colors-sky-800: #075985;
    --colors-sky-900: #0c4a6e;
    --colors-sky-950: #082f49;
    --colors-cyan-50: #ecfeff;
    --colors-cyan-100: #cffafe;
    --colors-cyan-200: #a5f3fc;
    --colors-cyan-300: #67e8f9;
    --colors-cyan-400: #22d3ee;
    --colors-cyan-500: #06b6d4;
    --colors-cyan-600: #0891b2;
    --colors-cyan-700: #0e7490;
    --colors-cyan-800: #155e75;
    --colors-cyan-900: #164e63;
    --colors-cyan-950: #083344;
    --colors-teal-50: #f0fdfa;
    --colors-teal-100: #ccfbf1;
    --colors-teal-200: #99f6e4;
    --colors-teal-300: #5eead4;
    --colors-teal-400: #2dd4bf;
    --colors-teal-500: #14b8a6;
    --colors-teal-600: #0d9488;
    --colors-teal-700: #0f766e;
    --colors-teal-800: #115e59;
    --colors-teal-900: #134e4a;
    --colors-teal-950: #042f2e;
    --colors-emerald-50: #ecfdf5;
    --colors-emerald-100: #d1fae5;
    --colors-emerald-200: #a7f3d0;
    --colors-emerald-300: #6ee7b7;
    --colors-emerald-400: #34d399;
    --colors-emerald-500: #10b981;
    --colors-emerald-600: #059669;
    --colors-emerald-700: #047857;
    --colors-emerald-800: #065f46;
    --colors-emerald-900: #064e3b;
    --colors-emerald-950: #022c22;
    --colors-green-50: #f0fdf4;
    --colors-green-100: #dcfce7;
    --colors-green-200: #bbf7d0;
    --colors-green-300: #86efac;
    --colors-green-400: #4ade80;
    --colors-green-500: #22c55e;
    --colors-green-600: #16a34a;
    --colors-green-700: #15803d;
    --colors-green-800: #166534;
    --colors-green-900: #14532d;
    --colors-green-950: #052e16;
    --colors-lime-50: #f7fee7;
    --colors-lime-100: #ecfccb;
    --colors-lime-200: #d9f99d;
    --colors-lime-300: #bef264;
    --colors-lime-400: #a3e635;
    --colors-lime-500: #84cc16;
    --colors-lime-600: #65a30d;
    --colors-lime-700: #4d7c0f;
    --colors-lime-800: #3f6212;
    --colors-lime-900: #365314;
    --colors-lime-950: #1a2e05;
    --colors-yellow-50: #fefce8;
    --colors-yellow-100: #fef9c3;
    --colors-yellow-200: #fef08a;
    --colors-yellow-300: #fde047;
    --colors-yellow-400: #facc15;
    --colors-yellow-500: #eab308;
    --colors-yellow-600: #ca8a04;
    --colors-yellow-700: #a16207;
    --colors-yellow-800: #854d0e;
    --colors-yellow-900: #713f12;
    --colors-yellow-950: #422006;
    --colors-amber-50: #fffbeb;
    --colors-amber-100: #fef3c7;
    --colors-amber-200: #fde68a;
    --colors-amber-300: #fcd34d;
    --colors-amber-400: #fbbf24;
    --colors-amber-500: #f59e0b;
    --colors-amber-600: #d97706;
    --colors-amber-700: #b45309;
    --colors-amber-800: #92400e;
    --colors-amber-900: #78350f;
    --colors-amber-950: #451a03;
    --colors-orange-50: #fff7ed;
    --colors-orange-100: #ffedd5;
    --colors-orange-200: #fed7aa;
    --colors-orange-300: #fdba74;
    --colors-orange-400: #fb923c;
    --colors-orange-500: #f97316;
    --colors-orange-600: #ea580c;
    --colors-orange-700: #c2410c;
    --colors-orange-800: #9a3412;
    --colors-orange-900: #7c2d12;
    --colors-orange-950: #431407;
    --colors-red-50: #fef2f2;
    --colors-red-100: #fee2e2;
    --colors-red-200: #fecaca;
    --colors-red-300: #fca5a5;
    --colors-red-400: #f87171;
    --colors-red-500: #ef4444;
    --colors-red-600: #dc2626;
    --colors-red-700: #b91c1c;
    --colors-red-800: #991b1b;
    --colors-red-900: #7f1d1d;
    --colors-red-950: #450a0a;
    --colors-neutral-50: #fafafa;
    --colors-neutral-100: #f5f5f5;
    --colors-neutral-200: #e5e5e5;
    --colors-neutral-300: #d4d4d4;
    --colors-neutral-400: #a3a3a3;
    --colors-neutral-500: #737373;
    --colors-neutral-600: #525252;
    --colors-neutral-700: #404040;
    --colors-neutral-800: #262626;
    --colors-neutral-900: #171717;
    --colors-neutral-950: #0a0a0a;
    --colors-stone-50: #fafaf9;
    --colors-stone-100: #f5f5f4;
    --colors-stone-200: #e7e5e4;
    --colors-stone-300: #d6d3d1;
    --colors-stone-400: #a8a29e;
    --colors-stone-500: #78716c;
    --colors-stone-600: #57534e;
    --colors-stone-700: #44403c;
    --colors-stone-800: #292524;
    --colors-stone-900: #1c1917;
    --colors-stone-950: #0c0a09;
    --colors-zinc-50: #fafafa;
    --colors-zinc-100: #f4f4f5;
    --colors-zinc-200: #e4e4e7;
    --colors-zinc-300: #d4d4d8;
    --colors-zinc-400: #a1a1aa;
    --colors-zinc-500: #71717a;
    --colors-zinc-600: #52525b;
    --colors-zinc-700: #3f3f46;
    --colors-zinc-800: #27272a;
    --colors-zinc-900: #18181b;
    --colors-zinc-950: #09090b;
    --colors-gray-50: #f9fafb;
    --colors-gray-100: #f3f4f6;
    --colors-gray-200: #e5e7eb;
    --colors-gray-300: #d1d5db;
    --colors-gray-400: #9ca3af;
    --colors-gray-500: #6b7280;
    --colors-gray-600: #4b5563;
    --colors-gray-700: #374151;
    --colors-gray-800: #1f2937;
    --colors-gray-900: #111827;
    --colors-gray-950: #030712;
    --colors-slate-50: #f8fafc;
    --colors-slate-100: #f1f5f9;
    --colors-slate-200: #e2e8f0;
    --colors-slate-300: #cbd5e1;
    --colors-slate-400: #94a3b8;
    --colors-slate-500: #64748b;
    --colors-slate-600: #475569;
    --colors-slate-700: #334155;
    --colors-slate-800: #1e293b;
    --colors-slate-900: #0f172a;
    --colors-slate-950: #020617;
    --blurs-sm: 4px;
    --blurs-base: 8px;
    --blurs-md: 12px;
    --blurs-lg: 16px;
    --blurs-xl: 24px;
    --blurs-2xl: 40px;
    --blurs-3xl: 64px;
    --spacing-0: 0rem;
    --spacing-1: 0.25rem;
    --spacing-2: 0.5rem;
    --spacing-3: 0.75rem;
    --spacing-4: 1rem;
    --spacing-5: 1.25rem;
    --spacing-6: 1.5rem;
    --spacing-7: 1.75rem;
    --spacing-8: 2rem;
    --spacing-9: 2.25rem;
    --spacing-10: 2.5rem;
    --spacing-11: 2.75rem;
    --spacing-12: 3rem;
    --spacing-14: 3.5rem;
    --spacing-16: 4rem;
    --spacing-20: 5rem;
    --spacing-24: 6rem;
    --spacing-28: 7rem;
    --spacing-32: 8rem;
    --spacing-36: 9rem;
    --spacing-40: 10rem;
    --spacing-44: 11rem;
    --spacing-48: 12rem;
    --spacing-52: 13rem;
    --spacing-56: 14rem;
    --spacing-60: 15rem;
    --spacing-64: 16rem;
    --spacing-72: 18rem;
    --spacing-80: 20rem;
    --spacing-96: 24rem;
    --spacing-0\.5: 0.125rem;
    --spacing-1\.5: 0.375rem;
    --spacing-2\.5: 0.625rem;
    --spacing-3\.5: 0.875rem;
    --sizes-0: 0rem;
    --sizes-1: 0.25rem;
    --sizes-2: 0.5rem;
    --sizes-3: 0.75rem;
    --sizes-4: 1rem;
    --sizes-5: 1.25rem;
    --sizes-6: 1.5rem;
    --sizes-7: 1.75rem;
    --sizes-8: 2rem;
    --sizes-9: 2.25rem;
    --sizes-10: 2.5rem;
    --sizes-11: 2.75rem;
    --sizes-12: 3rem;
    --sizes-14: 3.5rem;
    --sizes-16: 4rem;
    --sizes-20: 5rem;
    --sizes-24: 6rem;
    --sizes-28: 7rem;
    --sizes-32: 8rem;
    --sizes-36: 9rem;
    --sizes-40: 10rem;
    --sizes-44: 11rem;
    --sizes-48: 12rem;
    --sizes-52: 13rem;
    --sizes-56: 14rem;
    --sizes-60: 15rem;
    --sizes-64: 16rem;
    --sizes-72: 18rem;
    --sizes-80: 20rem;
    --sizes-96: 24rem;
    --sizes-0\.5: 0.125rem;
    --sizes-1\.5: 0.375rem;
    --sizes-2\.5: 0.625rem;
    --sizes-3\.5: 0.875rem;
    --sizes-xs: 20rem;
    --sizes-sm: 24rem;
    --sizes-md: 28rem;
    --sizes-lg: 32rem;
    --sizes-xl: 36rem;
    --sizes-2xl: 42rem;
    --sizes-3xl: 48rem;
    --sizes-4xl: 56rem;
    --sizes-5xl: 64rem;
    --sizes-6xl: 72rem;
    --sizes-7xl: 80rem;
    --sizes-8xl: 90rem;
    --sizes-prose: 65ch;
    --sizes-full: 100%;
    --sizes-min: min-content;
    --sizes-max: max-content;
    --sizes-fit: fit-content;
    --sizes-breakpoint-sm: 640px;
    --sizes-breakpoint-md: 768px;
    --sizes-breakpoint-lg: 1024px;
    --sizes-breakpoint-xl: 1280px;
    --sizes-breakpoint-2xl: 1536px;
    --animations-spin: spin 1s linear infinite;
    --animations-ping: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    --animations-pulse: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    --animations-bounce: bounce 1s infinite;
    --breakpoints-sm: 640px;
    --breakpoints-md: 768px;
    --breakpoints-lg: 1024px;
    --breakpoints-xl: 1280px;
    --breakpoints-2xl: 1536px;
}

  @keyframes spin {
    to {
      transform: rotate(360deg);
}
}

  @keyframes ping {
    75%,100% {
      transform: scale(2);
      opacity: 0;
}
}

  @keyframes pulse {
    50% {
      opacity: 0.5;
}
}

  @keyframes bounce {
    0%,100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8,0,1,1);
}

    50% {
      transform: none;
      animation-timing-function: cubic-bezier(0,0,0.2,1);
}
}
}

@layer utilities {
  .pos_top-center {
    position: top-center;
}

  .stroke_1\.5 {
    stroke: 1.5px;
}

  .d_flex {
    display: flex;
}

  .gap_10px {
    gap: 10px;
}

  .text_pink {
    color: pink;
}

  .order_1 {
    order: 1;
}

  .text_red {
    color: red;
}

  .w_30 {
    width: 30px;
}

  .p_xl {
    padding: xl;
}

  .order_2 {
    order: 2;
}

  .order_5 {
    order: 5;
}

  .my_md {
    margin-block: md;
}

  .d_grid {
    display: grid;
}

  .grid-template_\"sidebar_content\"_100vh_\/_auto_1fr\; {
    grid-template: "sidebar content" 100vh / auto 1fr;;
}

  .order_4 {
    order: 4;
}

  .shadow_sm {
    box-shadow: var(--shadows-sm);
}

  .py_lg {
    padding-block: lg;
}

  .px_xl {
    padding-inline: xl;
}

  .h_100vh {
    height: 100vh;
}

  .m_auto {
    margin: auto;
}

  .h_lg {
    height: var(--sizes-lg);
}

  .pos_right {
    position: right;
}

  .gap_xl {
    gap: xl;
}

  .gap_md {
    gap: md;
}

  .text_var\(--mantine-color-blue-7\) {
    color: var(--mantine-color-blue-7);
}

  .text_var\(--mantine-color-grape-7\) {
    color: var(--mantine-color-grape-7);
}

  .rounded_true {
    border-radius: true;
}

  .text_var\(--mantine-color-red-7\) {
    color: var(--mantine-color-red-7);
}

  .text_var\(--mantine-color-yellow-7\) {
    color: var(--mantine-color-yellow-7);
}

  .shadow_md {
    box-shadow: var(--shadows-md);
}

  .shadow_xs {
    box-shadow: var(--shadows-xs);
}

  .p_md {
    padding: md;
}

  .py_xs {
    padding-block: xs;
}

  .gap_xs {
    gap: xs;
}

  .px_md {
    padding-inline: md;
}

  .text_teal {
    color: teal;
}

  .w_200 {
    width: 200px;
}

  .pos_bottom {
    position: bottom;
}

  .text_orange {
    color: orange;
}

  .h_16 {
    height: var(--sizes-16);
}

  .order_6 {
    order: 6;
}

  .h_20 {
    height: var(--sizes-20);
}

  .py_md {
    padding-block: md;
}

  .p_sm {
    padding: sm;
}

  .h_28 {
    height: var(--sizes-28);
}

  .mx_md {
    margin-inline: md;
}

  .h_100 {
    height: 100px;
}

  .p_lg {
    padding: lg;
}

  .text_blue {
    color: blue;
}

  .d_none {
    display: none;
}

  .text_var\(--mantine-color-blue-filled\) {
    color: var(--mantine-color-blue-filled);
}

  .w_240px {
    width: 240px;
}

  .px_lg {
    padding-inline: lg;
}

  .w_100\% {
    width: 100%;
}

  .h_100\% {
    height: 100%;
}

  .mx_auto {
    margin-inline: auto;
}

  .mx_sm {
    margin-inline: sm;
}

  .order_3 {
    order: 3;
}

  .h_12 {
    height: var(--sizes-12);
}

  .text_teal\.7 {
    color: teal.7;
}

  .text_black {
    color: var(--colors-black);
}

  .text_gray {
    color: gray;
}

  .text_yellow {
    color: yellow;
}

  .bg_gray {
    background: gray;
}

  .pos_relative {
    position: relative;
}

  .p_calc\(2_\*_var\(--mantine-spacing-xl\)\) {
    padding: calc(2 * var(--mantine-spacing-xl));
}

  .bg_var\(--mantine-color-body\) {
    background: var(--mantine-color-body);
}

  .max-w_8xl {
    max-width: var(--sizes-8xl);
}

  .px_4 {
    padding-inline: var(--spacing-4);
}

  .my_auto {
    margin-block: auto;
}

  .py_0 {
    padding-block: var(--spacing-0);
}

  .px_0 {
    padding-inline: var(--spacing-0);
}

  .h_400px {
    height: 400px;
}

  .max-w_500px {
    max-width: 500px;
}

  .w_100 {
    width: 100px;
}

  .text_green {
    color: green;
}

  .items_center {
    align-items: center;
}

  .justify_center {
    justify-content: center;
}

  .flex_column {
    flex-direction: column;
}

  .mb_lg {
    margin-bottom: lg;
}

  .mt_xl {
    margin-top: xl;
}

  .mt_md {
    margin-top: md;
}

  .mr_md {
    margin-right: md;
}

  .mb_-12 {
    margin-bottom: calc(var(--spacing-12) * -1);
}

  .pb_md {
    padding-bottom: md;
}

  .pb_sm {
    padding-bottom: sm;
}

  .pt_md {
    padding-top: md;
}

  .ml_10 {
    margin-left: var(--spacing-10);
}

  .mt_lg {
    margin-top: lg;
}

  .mb_md {
    margin-bottom: md;
}

  .items_stretch {
    align-items: stretch;
}

  .pt_xl {
    padding-top: xl;
}

  .items_start {
    align-items: start;
}

  .mb_xl {
    margin-bottom: xl;
}

  .mt_100 {
    margin-top: 100px;
}

  .ml_lg {
    margin-left: lg;
}

  .ml_calc\(1\.5_\*_var\(--mantine-spacing-xl\)\) {
    margin-left: calc(1.5 * var(--mantine-spacing-xl));
}

  .pt_lg {
    padding-top: lg;
}

  .mr_xl {
    margin-right: xl;
}

  .ml_xl {
    margin-left: xl;
}

  @media screen and (min-width: 48rem) {
    .md\:px_6 {
      padding-inline: var(--spacing-6);
}
}

  @media screen and (min-width: 64rem) {
    .lg\:px_8 {
      padding-inline: var(--spacing-8);
}
}
}

