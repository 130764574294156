@import url('@mantine/core/styles.layer.css');
@import url('@mantine/notifications/styles.layer.css');
@import url('@mantine/nprogress/styles.layer.css');
@import url('@mantine/code-highlight/styles.layer.css');

@layer base, reset, mantine, tokens, recipes, utilities;

@font-face {
	font-family: 'Unbounded';
	src: url('/Unbounded-VariableFont_wght.ttf') format('truetype');
	font-weight: 100 900;
}

* {
	hyphens: auto;
}

html[data-mantine-color-scheme='dark'] {
	*::-webkit-scrollbar {
		width: 8px; /* Mostly for vertical scrollbars */
		height: 8px; /* Mostly for horizontal scrollbars */
	}

	*::-webkit-scrollbar-thumb {
		background: rgba(255 255 255 / 0.2);
		border-radius: 2px;
	}

	::-webkit-scrollbar-corner {
		background: transparent;
	}

	*::-webkit-scrollbar-track {
		background: rgba(255 255 255 / 0.1);
		border-radius: 2px;
		margin: 2px;
	}
}

html[data-mantine-color-scheme='light'] {
	*::-webkit-scrollbar {
		width: 8px; /* Mostly for vertical scrollbars */
		height: 8px; /* Mostly for horizontal scrollbars */
	}

	*::-webkit-scrollbar-thumb {
		background: rgba(0 0 0 / 0.2);
		border-radius: 2px;
	}

	::-webkit-scrollbar-corner {
		background: transparent;
	}

	*::-webkit-scrollbar-track {
		background: rgba(0 0 0 / 0.1);
		border-radius: 2px;
	}
}

:root {
	--default-border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);

	--overlay-filter: blur(10px);

	--mantine-color-body: oklch(95% 0 245.4);

	--paper-background-color: color-mix(in oklch, var(--mantine-color-body) 50%, white);
	--paper-background-color-2: color-mix(in oklch, var(--mantine-color-body) 40%, white);

	--paper-border: 1px solid rgba(0, 0, 0, 0.06);

	--debug-visibility: hidden;

	--input-bg: var(--mantine-color-gray-1);

	.jotai-devtools-trigger-button {
		left: unset;
		right: 1rem;
		bottom: 1rem;
		visibility: var(--debug-visibility);
	}
}

:root[data-mantine-color-scheme='dark'] {
	--default-border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);

	--mantine-color-body: oklch(15% 0 245.4);

	--paper-background-color: color-mix(in oklch, var(--mantine-color-body) 92%, white);
	--paper-background-color-2: color-mix(in oklch, var(--mantine-color-body) 90%, white);

	--paper-border: 1px solid rgba(255, 255, 255, 0.13);
	--mantine-color-text: rgb(240, 240, 240);
	--mantine-color-dimmed: color-mix(in oklch, var(--mantine-color-text) 82%, black);

	--input-bg: var(--mantine-color-dark-5);
}

@layer {
	a {
		text-decoration: none;
	}

	.mantine-Switch-root .mantine-Switch-body:has(input:not([disabled])) * {
		cursor: pointer;
	}

	.mantine-Modal-root {
		.mantine-Modal-content {
			border: 1px solid light-dark(rgba(0, 0, 0, 0.06), rgba(255, 255, 255, 0.03));
		}
	}

	.mantine-Paper-root {
		&[data-with-border] {
			/* border: 1px solid light-dark(rgba(0, 0, 0, 0.06), rgba(255, 255, 255, 0.1)); */
			border: var(--paper-border);
		}

		background-color: var(--paper-background-color);
	}
}

* {
	text-rendering: optimizeLegibility;
}

fieldset {
	border: none;
	padding: 0;
}

.tabler-icon {
	/* fill: currentColor; */
	stroke-width: 2px;
}

:root {
}
